/** @format */

import { adapterAvailableVehiclesToFamilies } from "../adapters/adapterAvailableVehiclesToFamilies";
import { postRequest } from "../config/axiosClient";
import { types } from "../types/types";
import { addNotificationModal } from "./notificationAction";
import { resetOptionalData } from "./optionalAction";
import { setSelectedDates } from "./summaryAction";

export const getAvailableVehicles = (obj) => {
  return async (dispatch) => {
    try {
      dispatch(setAvailableVehiclesLoading(true));
      dispatch(resetOptionalData());
      const startDate = obj.startDate;
      obj.startDate = new Date(
        obj.startDate - obj.startDate.getTimezoneOffset() * 60000
      );
      const endDate = obj.endDate;
      obj.endDate = new Date(
        obj.endDate - obj.endDate.getTimezoneOffset() * 60000
      );
      var { data } = await postRequest("rentals", obj);
      if (data?.length > 0) {
        dispatch(setFamiliesAvailables(data));
        dispatch(getAvailableVehiclesLoaded(data));
        dispatch(
          setSelectedDates({
            startDate: startDate,
            endDate: endDate,
          })
        );
      } else {
        dispatch(
          addNotificationModal(
            "No se han encontrado vehiculos disponibles entre las fechas indicadas para el concesionario seleccionado"
          )
        );
      }
      dispatch(setAvailableVehiclesLoading(false));
    } catch (error) {
      dispatch(setAvailableVehiclesLoading(false));
      dispatch(
        addNotificationModal(
          "No se han encontrado vehiculos disponibles entre las fechas indicadas para el concesionario seleccionado"
        )
      );
    }
  };
};

export const getAvailableVehiclesLoaded = (payload) => ({
  type: types.vehiclesGetListAvailableLoaded,
  payload,
});

export const setFamiliesAvailables = (vehicles) => {
  return async (dispatch) => {
    try {
      const families = adapterAvailableVehiclesToFamilies(vehicles);
      dispatch(setFamiliesAvailablesLoaded(families));
    } catch (error) {}
  };
};

export const setFamiliesAvailablesLoaded = (payload) => ({
  type: types.vehiclesSetFamiliesAvailablesLoaded,
  payload,
});

export const setAvailableVehiclesLoading = (payload) => ({
  type: types.vehiclesSetListAvailableLoading,
  payload,
});

export const resetVehicleAvailables = () => ({
  type: types.vehiclesAvailablesReset,
});
